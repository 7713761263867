<template>
  <div :id="baseClass + '-app'" :class="!isPc ? 'mobile' : 'pc'">
    <Header v-if="isPc" />
    <MobileHeader v-else />
    <div
      :class="[baseClass + '-content', !isPc ? 'mobile' : 'pc']"
      id="games-content"
    >
      <router-view />
    </div>
    <Actions v-if="!isPc" />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Actions from "@/components/MobileActions.vue";
import MobileHeader from "@/components/Mobile-header.vue"
export default {
  components: { MobileHeader, Header, Actions },
};
</script>
<style lang="scss">
@import "~@/assets/scss/base.scss";
</style>
