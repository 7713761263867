<template>
  <div :class="baseClass + '-mobile-actions'" v-if="$route.path.indexOf('/play/') == -1">
    <div
      :class="baseClass + '-mobile-actions-item'"
      v-for="item of actions"
      :key="item.path"
    >
      <img
        :class="baseClass + '-mobile-actions-item-icon'"
        :src="`/static/actions/${item.icon}${
          $route.path == item.path ? 2 : 1
        }.png`"
        @click="$router.push(item.path)"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actions: [
        { path: "/mobilehome", icon: "Icon_home1-" },
        { path: "/mobiletop", icon: "Icon_home2-" },
        { path: "/mobiletype", icon: "Icon_home3-" },
      ],
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss">
.#{$baseClass}-mobile-actions {
  width: 100vw;
  overflow: hidden;
  display: flex;
  background: #fedc32;
  height: 50px;
  justify-content: space-between;
  padding: 0 0;
  &-item {
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    &-icon {
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>
