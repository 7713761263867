import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/layout.vue"),
  },
  {
    path: "/index",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/mobilehome",
    component: () => import("../views/mobile-index.vue"),
  },
  {
    path: "/mobilelogin",
    component: () => import("../views/mobile-login.vue"),
  },
  {
    path: "/mobileregister",
    component: () => import("../views/mobile-register.vue"),
  },
  {
    path: "/mobiletype",
    component: () => import("../views/mobile-type.vue"),
  },
  {
    path: "/mobile/type/:id",
    component: () => import("../views/mobile-type-item.vue"),
  },
  {
    path: "/mobile/info/:id",
    component: () => import("../views/mobile-info.vue"),
  },
  {
    path: "/mobiletop",
    component: () => import("../views/mobile-top.vue"),
  },
  {
    path: "/type/:type",
    component: () => import("../views/type.vue"),
  },
  {
    path: "/info/:id",
    component: () => import("../views/info.vue"),
  },
  {
    path: "/play/:id",
    component: () => import("../views/play.vue"),
  },
  {
    path: "/about",
    component: () => import("../views/about.vue"),
  },
  {
    path: "/privacy",
    component: () => import("../views/privacy.vue"),
  },
  {
    path: "/terms",
    component: () => import("../views/terms.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/register",
    component: () => import("../views/register.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  store.dispatch("resetScrollTop");
  next();
});
export default router;
