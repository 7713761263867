<template>
  <div
    :class="[baseClass + '-game-item', type]"
    @click="$router.push(isPc ? `/info/${item.id}` : `/mobile/info/${item.id}`)"
  >
    <div v-if="type == 'main'" :class="baseClass + '-game-item-main'">
      <img class="img" :src="'/static' + item.img" />
    </div>
    <div v-else-if="type == 'search'" :class="baseClass + '-game-item-search'">
      <img class="img" :src="'/static' + item.img" />
    </div>
    <div v-else-if="type == 'top'" :class="baseClass + '-game-item-top'">
      <img class="img" :src="'/static' + item.img" />
    </div>
    <div v-else-if="type == 'new'" :class="baseClass + '-game-item-new'">
      <div :class="baseClass + '-game-item-new-banner'">
        <img class="img" :src="'/static' + item.img" />
        <div class="mask">
          <div class="btn">PLAY NOW</div>
        </div>
      </div>
      <div :class="baseClass + '-game-item-new-name'">
        {{ item.name }}
      </div>
    </div>
    <div v-else-if="type == 'all'" :class="baseClass + '-game-item-all'">
      <div :class="baseClass + '-game-item-all-banner'">
        <img class="img" :src="'/static' + item.img" />
        <div class="mask">
          <div class="btn">PLAY NOW</div>
        </div>
      </div>
      <div :class="baseClass + '-game-item-all-name'">
        {{ item.name }}
      </div>
    </div>
    <div
      v-else-if="type == 'mobile-new'"
      :class="baseClass + '-game-item-mobile-new'"
    >
      <img class="img" :src="'/static' + item.img" />
    </div>
    <div
      v-else-if="type == 'mobile-top'"
      :class="baseClass + '-game-item-mobile-new'"
    >
      <img class="img" :src="'/static' + item.img" />
    </div>
    <div
      v-else-if="type == 'mobile-item'"
      :class="baseClass + '-game-item-mobile-item'"
    >
      <img class="img" :src="'/static' + item.img" />
      <div :class="baseClass + '-game-item-mobile-item-name'">
        <div class="text">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "type"],
};
</script>

<style lang="scss">
@import "~@/assets/scss/game_item.scss";
@import "~@/assets/scss/game_item_screen.scss";
</style>
