<template>
  <div :class="baseClass + '-mobile-header'">
    <img @click="$router.push('/mobilehome')" class="logo" src="/static/actions/logo.png" alt="" />
    <div class="actions">
      <img class="btn1"  @click="$router.push('/mobilelogin')" src="/static/actions/btn2-1.png" alt="">
      <img class="btn2"  @click="$router.push('/mobileregister')" src="/static/actions/btn1-1.png" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.#{$baseClass}-mobile-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  background: #2B89FF;
  .logo{
    width: 100px;
    height: 24px;
      cursor: pointer;
      margin-left: 14px;
  }
  .actions{
    display: flex;
    justify-content: space-between;
    .btn1{
      width: 69px;
      height: 25px;
      cursor: pointer;
    }
    .btn2{
      width: 108px;
      height: 25px;
      cursor: pointer;
      margin: 0 15px 0 7px;
    }
  }
}
</style>