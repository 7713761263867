import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
function randomData(games, params, wList = []) {
  var _games = [];
  var iList = [];
  if (wList) {
    iList = [].concat(wList);
  }
  var l = games.length - 1;
  for (var i = 0; i < params; i++) {
    var _i = Math.round(Math.random() * l);
    if (iList.indexOf(_i) === -1) {
      iList.push(_i);
      _games.push(games[_i]);
    }
  }
  if (params > _games.length) {
    _games = [].concat(
      _games,
      randomData(games, params - _games.length, iList)
    );
  }
  return _games;
}
export default new Vuex.Store({
  state: {
    gameIdHash: {},
    gameTypeHash: {},
    typeList: [],
    mainGame: {},
    games: [],
    topGames: [],
    firsetGames: [],
    currentGame: {},
    mobileNew: [],
    mobileHot:[]
  },
  mutations: {},
  actions: {
    resetScrollTop() {
      document.body.scrollTop = 0;
      const content = document.getElementById("games-content");
      if (content) {
        content.scrollTop = 0;
      }
    },
    getGameInfo({ state }, { id, callback }) {
      let currentGame = {};
      for (let i = 0, length = state.firsetGames.length; i < length; i++) {
        const item = state.firsetGames[i];
        if (item.id == id) {
          currentGame = item;
          break;
        }
      }
      state.currentGame = currentGame;
      callback(currentGame);
    },
    randomData({ state }, { num, callback }) {
      let _games = randomData([].concat(state.firsetGames), num);
      callback(_games);
    },
    initGames({ state }, games) {
      state.firsetGames = [].concat(games);
      let _games = randomData(games, 100);
      state.games = [].concat(_games);
      state.topGames = [].concat(_games).splice(0, 10);
      state.mobileNew = [].concat(_games).splice(0, 3);
      state.mobileHot = [].concat(_games).splice(3, 3);
      state.mainGame = _games[10];
      let gameIdHash = {};
      let gameTypeHash = {};
      let typeList = [];
      for (const item of _games) {
        gameIdHash[item.id] = item;
        for (const type of item.category) {
          if (
            type &&
            [
              "Bejeweled",
              "Multiplayer",
              "2 Player",
              "Clicker",
              "Puzzles",
            ].indexOf(type) == -1
          ) {
            if (!gameTypeHash[type]) {
              gameTypeHash[type] = [];
              typeList.push(type);
            } else {
              gameTypeHash[type].push(item);
            }
          }
        }
      }
      state.gameIdHash = gameIdHash;
      state.gameTypeHash = gameTypeHash;
      state.typeList = typeList;
    },
  },
  modules: {},
});
