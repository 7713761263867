<template>
  <div :class="baseClass + '-header'" v-if="$route.path.indexOf('/play/') == -1">
    <div :class="baseClass + '-logo-box'">
      <img @click="$router.push('/')" :class="baseClass + '-logo'" src="~@/assets/images/logo.png" alt="" />
    </div>
    <div :class="baseClass + '-search-actions-box'">

      <div :class="baseClass + '-search-box'" :style="{
        height: searchList.length > 0 ? 'auto' : isPid ? '32px' : '68px',
      }">
        <div :class="baseClass + '-search'">
          <div :class="baseClass + '-icon-box'">
            <div :class="baseClass + '-icon'"></div>
          </div>
          <input type="text" :class="baseClass + '-input'" v-model="inputVal" placeholder="what are you playing today"
            @keydown="
            (event) => {
              return false;
            }
          " />
        </div>
        <div :class="baseClass + '-search-list'" v-if="searchList.length > 0">
          <Game v-for="item of searchList" :key="item.id" :item="item" type="search" />
        </div>
      </div>
      <img :class="baseClass + '-search-btn1'"  @click="$router.push('/login')" src="/static/actions/btn2.png" alt="">
      <img :class="baseClass + '-search-btn2'"  @click="$router.push('/register')" src="/static/actions/btn1.png" alt="">
    </div>
  </div>
</template>

<script>
import Game from "@/components/GameItem.vue";
export default {
  components: { Game },
  data () {
    return {
      inputVal: "",
      searchList: [],
    };
  },
  watch: {
    $route: {
      handler () {
        this.inputVal = null;
      },
      deep: true,
    },
    inputVal () {
      this.search();
    },
  },
  methods: {
    search () {
      const val = this.inputVal;
      let list = this.$store.state.games.filter((item) => {
        if (
          val &&
          (item.name.indexOf(val) !== -1 ||
            item.description.indexOf(val) !== -1)
        ) {
          return true;
        } else {
          return false;
        }
      });
      console.log(list);
      this.searchList = list;
    },
  },
};
</script>

<style lang="scss">
.#{$baseClass}-header {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100px;
  background: #2b89ff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  .#{$baseClass}-logo-box {
    display: flex;
    align-items: center;
    .#{$baseClass}-logo {
      width: 121px;
      height: 80px;
      cursor: pointer;
    }
  }
  .#{$baseClass}-search-btn1 {
    width: 132px;
    margin-right: 24px;
    height: 68px;
    position: relative;
    top: 16px;
    margin-left: 40px;
    cursor: pointer;
  }
  .#{$baseClass}-search-btn2 {
    width: 192px;
    height: 68px;
    position: relative;
    top: 16px;
    cursor: pointer;
  }
  .#{$baseClass}-search-actions-box{
    display: flex;
  }
  .#{$baseClass}-search-box {
    width: 480px;
    background: #006cf3;
    border-radius: 34px;
    position: relative;
    top: 16px;
    .#{$baseClass}-search-list {
      height: auto;
      max-height: 300px;
      overflow-y: auto;
      background: #006cf3;
      border-bottom-right-radius: 34px;
      border-bottom-left-radius: 34px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 34px;
    }
    .#{$baseClass}-search {
      width: 100%;
      height: 68px;
      display: flex;
      align-items: center;
      padding-right: 10px;
    }
    .#{$baseClass}-input {
      height: 25px;
      border: none;
      background: transparent;
      padding-left: 10px;
      flex: 1;
      outline: none;
      font-size: 20px;
      font-family: ArialMT;
      color: #ffffff;
      &::-webkit-input-placeholder {
        font-size: 20px;
        font-family: ArialMT;
        color: #ffffff;
      }
    }
    .#{$baseClass}-icon-box {
      width: 102px;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 36px;
      &::after {
        content: '';
        width: 4px;
        height: 18px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        position: absolute;
        right: 0;
      }
      .#{$baseClass}-icon {
        height: 44px;
        width: 44px;
        background-image: url('~@/assets/images/search-icon.png');
        background-size: 100% 100%;
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .#{$baseClass}-header {
    height: 60px;
    padding: 0 42px;
    .#{$baseClass}-logo-box {
      display: flex;
      align-items: center;
      .#{$baseClass}-logo {
        width: 62px;
        height: 40px;
      }
    }

    .#{$baseClass}-search-btn1 {
      width: 64px;
      margin-right: 12px;
      height: 32px;
      margin-left: 20px;
    }
    .#{$baseClass}-search-btn2 {
      width: 91px;
      height: 32px;
    }
    .#{$baseClass}-search-box {
      width: 270px;
      border-radius: 34px;
      top: 16px;
      .#{$baseClass}-search-list {
        max-height: 200px;
        padding: 0 14px;
      }
      .#{$baseClass}-search {
        width: 100%;
        height: 32px;
        padding-right: 10px;
      }
      .#{$baseClass}-input {
        height: 25px;
        padding-left: 10px;
        font-size: 12px;
        &::-webkit-input-placeholder {
          font-size: 12px;
        }
      }
      .#{$baseClass}-icon-box {
        width: 57px;
        padding-left: 19px;
        &::after {
          width: 2px;
          height: 10px;
        }
        .#{$baseClass}-icon {
          height: 22px;
          width: 22px;
        }
      }
    }
  }
}
</style>
