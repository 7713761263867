import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import games from "@/assets/scripts/data.js";
Vue.config.productionTip = false;
Vue.prototype.baseClass = "kizgamer";
Vue.prototype.isPc = window.outerWidth > 710;
Vue.prototype.isPid = window.outerWidth > 710 && window.outerWidth < 1300;
store.dispatch("initGames", games);
window.onresize = ()=>{
  Vue.prototype.isPc = window.outerWidth > 710;
  Vue.prototype.isPid = window.outerWidth > 710 && window.outerWidth < 1300;
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
